import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { CircularProgress, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import '../../assets/css/app.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Navbar from './nav/Navbar';
import Footer from '../main/nav/Footer';
import { apiContext } from '../../api';
import { LocalizationProvider } from '@mui/lab';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
const mdTheme = createTheme();
const columns = [
    { id: 'id', label: 'No', width: 2 },
    { id: 'nama', label: 'Nama Penulis', minWidth: 13 },
    { id: 'judul', label: 'Judul', minWidth: 13 },
    { id: 'tahun', label: 'Tahun', minWidth: 13 },
    { id: 'file', label: 'File Publikasi', minWidth: 13 },
    { id: 'option', label: 'Option', minWidth: 5 },

];

export default function NonPublish() {
    const [loading, setLoading] = React.useState(false);
    const { axios,API_URL } = React.useContext(apiContext);
    const user_id = localStorage.getItem("user_id");
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [jurnal, setJurnal] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [id, setId] = React.useState(null);
    const [nama, setNama] = React.useState("");
    const [tahun, setTahun] = React.useState("");
    const [judul, setJudul] = React.useState("");
    const [url, setUrl] = React.useState("");
    const [tempat, setTempat] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [keterangan, setKeterangan] = React.useState("");
    const [edit, setEdit] = React.useState(false);
    const [file,setFile] = React.useState("");
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    var count = 0;

    const fetchData = async () => {
        const response = await axios.get(`/nonpublish-get/${user_id}`);
        const data = response.data;
        if (data) {
            setJurnal(data);
        }

    }
    const editData = async (id) => {
        setLoading(id);
        const response = await axios.get(`/nonpublish-edit/${id}`);
        const data = response.data;
        if (data) {
            setEdit(true);
            setId(data.id);
            setNama(data.nama);
            setTahun(data.tahun);
            setJudul(data.judul);
            setUrl(data.url);
            setTempat(data.tempat);
            setEmail(data.email);
            setKeterangan(data.keterangan);
            toggleDrawer();
            setLoading(false);
        }
    }
    const deleteData = (event, id) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Data tidak dapat dikembalikan jika dihapus",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus'
        }).then((result) => {
            if (result.isConfirmed) {
                deleted(event, id);
            }
        })

    }
    React.useEffect(() => {
        fetchData();
    }, [])

    const resetInput = () => {
        setId(null);
        setNama("");
        setTahun("");
        setJudul("");
        setTempat("");
        setUrl("");
        setEmail("");
        setKeterangan("");
        document.getElementById("file").value = "";
    }
    const cancelEdit = () => {
        resetInput();
        setEdit(false);
        toggleDrawer();
    }
    const deleted = async (event, id) => {
        event.preventDefault();
        await axios.delete(`/nonpublish-delete/${id}`)
            .then(
                response => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Berhasil Dihapus',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    fetchData();
                }

            )
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba kembali',
                })
            });

    }
    const submit = async event => {
        event.preventDefault();
        setLoading(true);
        const data = new FormData();
        data.set('nama', nama);
        data.set('tahun', tahun);
        data.set('judul', judul);
        data.set('tempat', tempat);
        data.set('url', url);
        data.set('email', email);
        data.set('keterangan', keterangan);
        data.append('file', file);
        
        if (edit) {
            await axios.post(`/nonpublish-update/${id}`, data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(
                    response => {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchData();
                        resetInput();
                        setEdit(false);
                        toggleDrawer();
                        setLoading(false)
                    }

                )
                .catch(error => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Gagal',
                        text: 'Mohon coba input kembali',
                    })
                });

        } else {
            await axios.post('/nonpublish-post', data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
                .then(
                    response => {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchData();
                        resetInput();
                        toggleDrawer();
                        setLoading(false)
                    }

                )
                .catch(error => {
                    setLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Gagal',
                        text: 'Mohon coba input kembali',
                    })
                });

        }

    }
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar title={'Laporan yang tidak dipublikasikan'} />
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Paper className={`bg-card collapse ${open ? 'show' : 'hide'}`} sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
                            <form onSubmit={submit}>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <div className='form-group mb-2'>
                                            <TextField required variant="outlined" label="Nama Penulis" value={nama} className="w-100" onChange={e => setNama(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='form-group mb-2'>
                                            <TextField
                                                label="Tahun (Hanya Diisi Tahun)"
                                                type='number'
                                                variant="outlined"
                                                required
                                                sx={{
                                                    width: '100%'
                                                }}
                                                value={tahun}
                                                onChange={e => setTahun(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <div className='form-group mb-2'>
                                            <TextField required variant="outlined" label="Judul laporan" value={judul} className="w-100" onChange={e => setJudul(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='form-group mb-2'>
                                            <TextField variant="outlined" label="Email" value={email} className="w-100" onChange={e => setEmail(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <div className='form-group mb-2'>
                                            <TextField variant="outlined" label="Tempat" value={tempat} className="w-100" onChange={e => setTempat(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='form-group mb-2'>
                                            <TextField variant="outlined" label="Url/Link" value={url} className="w-100" onChange={e => setUrl(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <div className='form-group mb-2'>
                                            <TextField multiline
                                                rows={6}
                                                label="Keterangan Lainya"
                                                defaultValue=""
                                                value={keterangan} className="w-100" onChange={e => setKeterangan(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='mb-4'>
                                            <p className='fs-6 fst-italic main-color'>
                                                <small>Upload Bukti Fisik Seminar (Cover/Abstrak/Daftar Isi/Resume/Dsb) File Artikel Seminar (Format pdf, Ukuran besar file maksimal 10 MB)</small>
                                            </p>
                                            <input type="file" id='file' accept="application/pdf" variant="outlined" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 }, }} onChange={(e) => { setFile(e.target.files[0]) }} />
                                        </div>
                                        <Button type='button' variant="outlined" color="error" onClick={cancelEdit} className="float-start">Batal</Button>
                                        <Button type='submit' disabled={loading ? true : false} variant="contained" className='float-end'>
                                            {loading ? <CircularProgress size="1.5rem" className='text-white' /> : edit ? 'Update' : 'Simpan'}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </Paper>
                        {
                            !open ? <Link to={'/Publikasi'} className='text-decoration-none text-dark'>
                                <ArrowBackIcon /> Kembali
                            </Link> : <></>
                        }
                        <Button onClick={() => { toggleDrawer() }} variant="contained" className={`float-end ${open ? 'd-none' : 'd-block'}`}>Tambah</Button>
                    </Container>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Paper className={`bg-card collapse ${open ? 'hide' : 'show'}`} sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ width: column.width }}
                                                >
                                                    <strong>{column.label}</strong>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {jurnal
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                count += 1;
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                        <TableCell>
                                                            {count}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.nama}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.judul}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.tempat}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.file ? <a target="_blank" href={API_URL + '/storage/' + row.file}>
                                                                <PictureAsPdfIcon className='mx-auto' color="error" />
                                                            </a> : 'TIDAK ADA'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {
                                                                loading === row.id ? <Button variant='text'>
                                                                    <CircularProgress size="1.5rem" />
                                                                </Button> : <Button variant='text' onClick={() => { editData(row.id) }}>
                                                                    <EditIcon />
                                                                </Button>
                                                            }
                                                            <Button variant='text' color='error' onClick={(event) => { deleteData(event, row.id) }}><DeleteIcon /></Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={jurnal.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        <Footer />

                    </Container>
                </Box>
            </Box >
        </ThemeProvider >
    );
}
