import * as React from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AppBar from './AppBar';
import Drawer from './Drawer';
import { Button, Toolbar } from '@mui/material';
import unsam_logo from '../../../assets/img/unsam.png';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { MainListItemsReview, mainListItems, secondaryListItems } from './listItemsReview';

export default function NavbarReview({ title}) {
    const user_id = localStorage.getItem("user_id");
    
    const [open, setOpen] = React.useState(isMobile? false:true);

    const toggleDrawer = () => {
        setOpen(!open);
    };
    return(
        <>
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px',
                    }}
                    className='text-white header-color'
                >
                    {
                        !isMobile? 
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>:<></>
                    }
                 
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        {title}
                    </Typography>
                   
                </Toolbar>
            </AppBar>
            <Drawer className='' variant="permanent" open={open}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }}
                >
                    <img src={unsam_logo} width="50px" alt='logo' />
                    <span className='me-2'></span>
                    {
                        !isMobile? 
                            <IconButton sx={{
                                marginLeft: 'auto',

                            }} onClick={toggleDrawer}>
                                <ChevronLeftIcon />
                            </IconButton>: <></>
                    }
                
                </Toolbar>
                <Divider />
                <List><MainListItemsReview/></List>
                <Divider />
                <List>{secondaryListItems}</List>
            </Drawer>
        </>
    )
}