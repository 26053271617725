import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { Container, InputAdornment, Toolbar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { isMobile } from 'react-device-detect';
import HeaderLogin from '../../main/nav-login/HeaderLogin';
import Footer from '../../main/nav/Footer';
import GrafikJurnal from '../GrafikJurnal';
import { apiContext } from '../../../api';
import Chart from "react-apexcharts";
import Biodata from '../component/Biodata';
import Jurnal from '../component/Jurnal';
const theme = createTheme();
export default function PublikasiAll() {
    const { state } = useParams();
    const navigate = useNavigate();
    const gotoGrafik = () => {
        navigate('/');
    }
    const [data, setData] = useState([]);
    const [publikasi, setPublikasi] = useState([]);

    const fetchData = async () => {
        const { data } = await axios.get(`/data-get`);
        if (data) {
            setData(data);
        }
        
    }
    const fetchPublikasi = async () => {
        const { data } = await axios.get(`/publikasiall-get/${state}`);
        if (data) {
            setPublikasi(data);
        }
        return { data };
    }
    useEffect(() => {
        fetchData();
        fetchPublikasi();
    }, [])

    return ( 
        <ThemeProvider theme={theme}>
            <HeaderLogin data={data} grafik={gotoGrafik} />
            <Grid item xs={12} sm={8} md={3} className={`position-absolute login-container bg-transparent `} component={Paper} elevation={6} square style={{ boxShadow: 'none', marginLeft: '40px' }}>
                <Box
                    className='bookmark'
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: { xs: '129px', md: '203px' }, margin: '0px 0px',
                        height: { xs: '141px', md: '212px' },

                    }}
                >

                    {/* <Typography component="h1" variant="h5">
                            Login
                        </Typography> */}

                    {/* <Footer /> */}
                    <div className='w-100 mb-3 text-center mt-1 '>

                    </div>
                </Box>
            </Grid>
            <Jurnal data={publikasi} fetchPublikasi={fetchPublikasi} setPublikasi={setPublikasi} state={state}/> 
        </ThemeProvider>
    );
}