import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { Container, InputAdornment, Toolbar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { isMobile } from 'react-device-detect';
import HeaderLogin from '../../main/nav-login/HeaderLogin';
import Footer from '../../main/nav/Footer';
import GrafikJurnal from '../GrafikJurnal';
import { apiContext } from '../../../api';
import Chart from "react-apexcharts";
import Biodata from '../component/Biodata';
const theme = createTheme();
export default function ProfilP() {
    const { id } = useParams();
    const grafik = useRef(null);
    const gotoGrafik = () => grafik.current.scrollIntoView()
    const { API_URL } = useContext(apiContext);
    const [user, setUser] = useState('');
    const fetchUser = async () => {
        const { data } = await axios.get(`/user-view/${id}`);
        if (data) {
            setUser(data)
        }
    }
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const fetchData = async () => {
        const { data } = await axios.get(`/data-get`);
        if (data) {
            setData(data);
        }
    }
    useEffect(() => {
        fetchData();
        fetchUser();
    }, [])
    useEffect(() => {
        const keyDownHandler = e => {

            if (e.key === 'Enter') {
                e.preventDefault();
                navigate('/cari', { state: { param: e.target.value } })
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <HeaderLogin data={data} grafik={gotoGrafik} />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Biodata user={user} />
                    <div className='pt-4 justify-content-center w-100' style={{ gap: '40px' }}>
                        {
                            <Chart
                                options={{
                                    chart: {
                                        events: {
                                            dataPointSelection: (event, chartContext, config) => {
                                                navigate(`/public/profil/${id}/${config.w.config.labels[config.dataPointIndex]}`)
                                            }
                                        }
                                    },
                                    plotOptions: {
                                        bar: {
                                            horizontal: true,
                                        }
                                    },
                                    dataLabels: {
                                        enabled: true
                                    },
                                    labels: ['Seminar', 'Buku', 'Paten', 'Jurnal', 'Prosiding', 'Laporan', 'Opini', 'Karya', 'Poster']
                                    ,
                                    grid: {
                                        xaxis: {
                                            lines: {
                                                show: true
                                            }
                                        }
                                    },
                                    yaxis: {
                                        reversed: false,
                                        axisTicks: {
                                            show: true
                                        }
                                    },
                                }}
                                series={[{
                                    data: [user.seminar_count, user.buku_count, user.paten_count, user.jurnal_count, user.prosiding_count, user.non_publish_count, user.opini_count, user.karya_count, user.poster_count]
                                }]}
                                type={'bar'}
                                height="400"
                            />
                        }
                    </div>
                </Grid>
            </Container>
        </ThemeProvider>
    );
}