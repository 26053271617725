import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Navbar from './nav/Navbar';
import Footer from '../main/nav/Footer';
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import BarChartIcon from '@mui/icons-material/BarChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import Chart from "react-apexcharts";
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import FontDownloadRoundedIcon from '@mui/icons-material/FontDownloadRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
const mdTheme = createTheme();

export default function GrafikJurnal({ auth }) {
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [state, setState] = React.useState(false);
    const [series, setSeries] = React.useState([]);
    const [option, setOption] = React.useState([]);
    const [tahun, setTahun] = React.useState([]);
    const [fakultas, setFakultas] = React.useState([]);
    const [prodi, setProdi] = React.useState([]);
    const [prodiValue, setProdiValue] = React.useState("");
    const [fakultasValue, setFakultasValue] = React.useState("");
    const [jenis, setJenis] = React.useState();
    const [tahunValue, setTahunValue] = React.useState("");
    const onStateChange = (state) => {
        fetchData(state)
    }
    const onChangeFakultas = (state) => {
        fetchData('fakultas', state)
        setFakultasValue(state);
    }
    const onChangeProdi = (state) => {
        fetchData('prodi', state)
        setProdiValue(state)
    }
    const onChangeJenis = (state) => {
        fetchData('jenis', state);
        setJenis(state)
    }
    const fetchTahun = async () => {
        const { data } = await axios.get('/tahun');
        console.log(data[data.length - 1])
        if (data) {
            setTahunValue(data[data.length - 1]);
        }
    }
    const fetchData = async (state, value = null) => {
        setState(false);
        let data;
        if (state === 'jenis') {
            const url = await axios.get(`/grafikJurnal/${state}/${tahunValue}/${value}${fakultasValue ? '/' + fakultasValue : ''}${prodiValue ? '/' + prodiValue : ''}`);
            data = url.data;
        } else {
            const url = await axios.get(`/grafikJurnal/${state}/${tahunValue}/${value}`);
            data = url.data;
        }
        if (data) {
            if (state === 'fakultas') {
                console.log(data)
                optionFakultas(Object.keys(data.data), 'Fakultas', value)
                seriesFakultas(data, value)
                setProdi(data.prodi);
            } else if (state === 'prodi') {
                optionFakultas(Object.keys(data.prodi), 'Prodi', value)
                seriesProdi(data, value)
            } else if (state === 'jenis') {
                if (value === 'semua') {
                    optionFakultas(data.tahun, value)
                } else {
                    optionFakultas(Object.keys(data.data), value)
                }
                seriesJenis(data, value)

            } else {
                optionTahun(data.jurnal)
                seriesTahun(data.jurnal)
            }
            // if (state === 'tahunan') {
            //     optionTahun(data.jurnal)
            //     seriesTahun(data.jurnal)
            // } else if (state === 'jenis') {
            //     optionJenis(data)
            //     seriesJenis(data)
            // } else if (state === 'fakultas') {
            //     optionFakultas(data)
            //     seriesFakultas(data)
            // }
            setTahun(data.tahun);
            setFakultas(data.fakultas);
            setState(state);
        }
    }
    const seriesTahun = (data) => {
        console.log(data)
        setSeries([
            {
                name: "Jurnal Tahunan",
                data: Object.values(data)
            }
        ])
    }

    const optionTahun = (data) => {
        setOption({
            chart: {
                id: "bar"
            },
            xaxis: {
                categories: Object.keys(data)
            }
        })
    }
    const seriesJenis = (data, value) => {
        if (value === 'semua') {
            setSeries([
                {
                    name: "Jurnal Internasional Bereputasi",
                    data: Object.values(data.jib)
                },
                {
                    name: "Jurnal Nasional Terakreditasi",
                    data: Object.values(data.jnt)
                },
                {
                    name: "Jurnal Nasional Tidak Terakreditasi (Memiliki ISSN)",
                    data: Object.values(data.jnissn)
                },
                {
                    name: "Jurnal Internasional",
                    data: Object.values(data.ji)
                },
            ])
        } else {
            setSeries([
                {
                    name: value,
                    data: Object.values(data.data)
                }
            ])
        }

    }
    const seriesProdi = (data, value) => {
        setSeries([{
            name: value,
            data: Object.values(data.prodi)
        }])
    }
    const seriesFakultas = (data, fakultasValue) => {
        let y;
        if (fakultasValue === 'semua') {
            y = [
                {
                    name: "FH",
                    data: Object.values(data.hukum)
                },
                {
                    name: "FE",
                    data: Object.values(data.ekonomi)
                },
                {
                    name: "FP",
                    data: Object.values(data.pertanian)
                },
                {
                    name: "FKIP",
                    data: Object.values(data.fkip)
                },
                {
                    name: "FT",
                    data: Object.values(data.teknik)
                },
            ]
        } else {
            y = [
                {
                    name: fakultasValue,
                    data: Object.values(data.data)
                }
            ]
        }
        setSeries(y)
    }
    const optionJenis = (data, value) => {
        setOption({
            chart: {
                height: 400,
                type: 'line',
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                width: [5, 7, 5],
                curve: 'straight',
                dashArray: [0, 8, 5]
            },
            legend: {
                tooltipHoverFormatter: function (val, opts) {
                    return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
                }
            },
            markers: {
                size: 0,
                hover: {
                    sizeOffset: 6
                }
            },
            tooltip: {
                y: [
                    {
                        title: {
                            formatter: function (val) {
                                return val
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val + " Jenis"
                            }
                        }
                    },
                    {
                        title: {
                            formatter: function (val) {
                                return val;
                            }
                        }
                    }
                ]
            },
            grid: {
                borderColor: '#f1f1f1',
            },
            xaxis: {
                categories: data.tahun
            }
        })
    }
    const optionFakultas = (data, state, value) => {
        setOption({
            plotOptions: {
                bar: {
                    dataLabels: {
                        style: {
                            colors: ['red', 'yellow', 'green', 'blue', 'orange']
                        },
                        total: {
                            enabled: true,
                            offsetX: 0,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {
                text: `Perbandingan Jumlah Publikasi JURNAL dalam 5 Tahun Terakhir, per ${state}`
            },
            xaxis: {
                categories: data,
            },
            yaxis: {
                title: {
                    text: undefined
                },
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            colors: ['red', 'yellow', 'green', 'blue', 'orange'],
            fill: {
                opacity: 1,
                colors: ['#FF0000', '#FFFF00', '#008000', '#0000FF', '#FFA500']
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        })
    }
    React.useEffect(() => {
        fetchData('tahunan');
    }, [tahunValue])
    React.useEffect(() => {
        fetchTahun();
    }, [])
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                {auth ? <Navbar title={'Statistik'} /> : <></>}
                <Box
                    component="main"

                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container sx={{ p: 0, mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        boxShadow: 'none'
                                    }}
                                >

                                    <div className='d-flex' style={{ gap: '5px' }}>
                                        <FormControl className="w-25">
                                            <InputLabel>Tahun</InputLabel>
                                            <Select
                                                label="Tahun"
                                                value={tahunValue}
                                                className="text-primary"
                                                onChange={(e) => { setTahunValue(e.target.value) }}
                                            >
                                                {
                                                    tahun.map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} className="text-primary">{item}</MenuItem>
                                                        )
                                                    })
                                                }

                                            </Select>
                                        </FormControl>
                                        <FormControl className="w-25">
                                            <InputLabel>Fakultas</InputLabel>
                                            <Select
                                                label="Fakultas"
                                                value={fakultasValue}
                                                className="text-primary"
                                                onChange={(e) => { onChangeFakultas(e.target.value) }}
                                            >
                                                <MenuItem value={''} className="text-primary"></MenuItem>
                                                <MenuItem value={'semua'} className="text-primary">Semua</MenuItem>
                                                {
                                                    fakultas?.map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} className="text-primary">{item}</MenuItem>
                                                        )
                                                    })
                                                }

                                            </Select>
                                        </FormControl>
                                        <FormControl className="w-25">
                                            <InputLabel>Prodi</InputLabel>
                                            <Select
                                                label="Prodi"
                                                value={prodiValue}
                                                className="text-primary"
                                                onChange={(e) => { onChangeProdi(e.target.value) }}
                                            >
                                                <MenuItem value={''} className="text-primary"></MenuItem>
                                                {
                                                    prodi.map((item, index) => {
                                                        return (
                                                            <MenuItem value={item} className="text-primary">{item}</MenuItem>
                                                        )
                                                    })
                                                }

                                            </Select>
                                        </FormControl>
                                        <FormControl className="w-25">
                                            <InputLabel>Jenis Jurnal</InputLabel>
                                            <Select
                                                label="Jenis Jurnal"
                                                value={jenis}
                                                className="text-primary"
                                                onChange={(e) => { onChangeJenis(e.target.value) }}
                                            >
                                                <MenuItem value={''} className="text-primary"></MenuItem>
                                                <MenuItem value={'semua'} className="text-primary">Semua</MenuItem>
                                                <MenuItem value={'JIB'} className="text-primary">Jurnal Internasional Bereputasi</MenuItem>
                                                <MenuItem value={'JNT'} className="text-primary">Jurnal Nasional Terakreditasi</MenuItem>
                                                <MenuItem value={'JNISSN'} className="text-primary">Jurnal Nasional Tidak Terakreditasi (Memiliki ISSN)</MenuItem>
                                                <MenuItem value={'JI'} className="text-primary">Jurnal Internasional</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {/* <FormControl className="w-25">
                                            <InputLabel>Grafik</InputLabel>
                                            <Select
                                                label="Grafik"
                                                value={state}
                                                className="text-primary"
                                                onChange={(e) => { onStateChange(e.target.value) }}
                                            >
                                                <MenuItem value={'tahunan'} className="text-primary"><DateRangeRoundedIcon color="primary" className='me-3' /> Per-5 Tahun</MenuItem>
                                                <MenuItem value={'jenis'} className="text-primary"><FontDownloadRoundedIcon color="primary" className='me-3' /> Perjenis Jurnal</MenuItem>
                                                <MenuItem value={'fakultas'} className="text-primary"><CorporateFareRoundedIcon color="primary" className='me-3' /> PerFakultas</MenuItem>
                                            </Select>
                                        </FormControl> */}
                                    </div>
                                    <Divider className='my-2 main-color' />
                                    {
                                        state ?
                                            <Chart
                                                options={option}
                                                series={series}
                                                type={'bar'}
                                                height="400"
                                            />
                                            : <></>
                                    }
                                    <div className="text-center">
                                        <p>Grafik Publikasi Dalam 5 Tahun Terakhir</p>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                        {
                            auth ? <Footer /> : <></>
                        }
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
