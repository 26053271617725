import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import unsam_logo from '../../../assets/img/unsam.png'
import scopus from '../../../assets/img/scopus.png'
import thomson from '../../../assets/img/thomsonReuters.png'
import google from '../../../assets/img/googleScholar.png'
import sinta from '../../../assets/img/garuda.png'
import { Box, Divider, IconButton, List, StyledEngineProvider, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MovingText from 'react-moving-text'
import { apiContext } from '../../../api';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '../../user/nav/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems } from '../../user/nav/listItems';
import DrawerLogin from './Drawer';
import { isDesktop, isTablet } from 'react-device-detect';

const theme = createTheme();
function HeaderLogin({ data, grafik }) {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };
    const executeScroll = (ref) => ref.current.scrollIntoView()
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <StyledEngineProvider injectFirst>
                    <Toolbar
                        className='p-0 header-color text-white'
                        variant="dense"
                        style={{ height: '90px' }}
                        sx={{ boxShadow: 0, justifyContent: 'space-between' }} xs={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '#28a745!important', position: 'fixed', top: 0, width: '100%', py: 0.5 }} >
                        <Box maxWidth="lg" className='p-0 w-100' sx={{ margin: { sm: '0', md: '0px 112px' } }}>
                            <div className="row">
                                <div className='p-0' style={{ width: '150px' }}>
                                </div>
                                <Box className='col-md-7 p-0 me-auto' sx={{ display: { xs: 'none', md: 'block' } }}>
                                    <Button className="bg-transparent text-white menu-text" onClick={grafik}>Grafik</Button>
                                    <Link to={'/repository'} className='text-decoration-none '>
                                        <Button className="bg-transparent text-white menu-text">Repository</Button>
                                    </Link>
                                </Box>
                                <Box className='col-md-1 text-end' sx={{ display: { xs: 'none', md: 'block' } }}>
                                    <Link to={'/login'} className='text-decoration-none' >
                                        <Button className="main-color menu-text login px-5 fw-bold">Login</Button>
                                    </Link>
                                </Box>
                                <Box className="col text-end" sx={{ display: { xs: 'block', md: 'none' } }}>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="open drawer"
                                        className='text-white'
                                        onClick={toggleDrawer}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Box>

                            </div>

                        </Box>
                    </Toolbar>
                    <Toolbar component="nav"
                        className='toolbar-login p-0'
                        variant="dense"
                        sx={{ boxShadow: 3, justifyContent: 'space-between' }} xs={{ borderBottom: 1, borderColor: 'divider', backgroundColor: '#28a745!important', position: 'fixed', top: 0, width: '100%', py: 0.5 }} >
                        <div className='p-0 mx-0 w-100'>
                            <div className='d-flex w-100'>
                                <div className='p-0 ' style={{ width: '250px' }}>

                                </div>
                                <Box className={`col-md-7 ps-2`}>
                                    {/* <MovingText
                                type="fadeInFromTop"
                                duration="3500ms"
                                delay="1s"
                                direction="normal"
                                timing="ease"
                                iteration="infinite"
                                fillMode="none">
                                Jumlah Akun RPU {data.akun} | Scopus {data.scopus} <img src={scopus} alt="scopus" width="30" /> | Thomson {data.thomson} <img src={thomson} alt="thomson" width="30" /> | Google {data.google} <img src={google} alt="google" width="30" /> | Sinta {data.sinta} <img src={sinta} alt="sinta" width="30" />
                            </MovingText> */}
                                    <Box className='align-items-center' sx={{ display: { xs: 'none',sm:'flex', md: 'flex' }, gap: '15px' }}>
                                        <b> <PersonRoundedIcon /> {data.akun} Users</b> |
                                        <b><img src={scopus} alt="scopus" width="30" /> {data.scopus}</b> |
                                        <b><img src={thomson} alt="thomson" width="80" /> {data.thomson}</b> |
                                        <b><img src={google} alt="google" width="30" /> {data.google}</b> |
                                        <b><img src={sinta} alt="sinta" width="80" /> {data.sinta}</b>
                                    </Box>
                                    <Box sx={{ maxWidth: '215px', overflow: 'hidden', display: { xs: 'flex',sm:'none', md: 'none' } }}>
                                        <p className="move d-flex align-items-center" style={{ gap: '15px' }}>
                                            <b> <PersonRoundedIcon /> {data.akun} Users</b> |
                                            <b><img src={scopus} alt="scopus" width="30" /> {data.scopus}</b> |
                                            <b><img src={thomson} alt="thomson" width="80" /> {data.thomson}</b> |
                                            <b><img src={google} alt="google" width="30" /> {data.google}</b> |
                                            <b><img src={sinta} alt="sinta" width="80" /> {data.sinta}</b>
                                        </p>
                                    </Box>
                                </Box>
                                {isDesktop ?
                                    <Box className='col text-end' sx={{ display: { xs: 'none', md: 'flex' } }}>
                                        <div class="h-100 text-end d-flex align-items-center fw-bold">
                                            | {moment(new Date()).lang("id").format('dddd, D MMMM YYYY')}
                                        </div>
                                    </Box> : <></>
                                }

                            </div>
                        </div>
                    </Toolbar>
                </StyledEngineProvider>
            </ThemeProvider>
            <DrawerLogin variant="permanent" open={open}>
                <Toolbar

                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [1],
                    }} >
                    <IconButton
                        className="text-white"
                        sx={{
                            marginLeft: 'auto',
                        }} onClick={toggleDrawer}>
                        <ChevronLeftIcon />
                    </IconButton>
                </Toolbar>
                <Divider className='bg-white' />
                <List>
                    <li>
                        <Link to={'/login'} className='text-decoration-none' >
                            <Button className="bg-transparent text-white menu-text">Login</Button>
                        </Link>
                    </li>
                    <li>
                        <Button className="bg-transparent text-white menu-text" onClick={() => {
                            grafik();
                            toggleDrawer()
                        }}>Grafik</Button>
                    </li>
                    <li>
                        <Link to={'/repository'} className='text-decoration-none '>
                            <Button className="bg-transparent text-white menu-text">Repository</Button>
                        </Link>
                    </li>

                </List>
            </DrawerLogin>
        </React.Fragment >
    );
}

export default HeaderLogin;