import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Divider, Grid, Paper, Typography } from '@mui/material';
import React from 'react';
import { apiContext } from '../../../api';
export default function Biodata({user}){
    const { API_URL } = React.useContext(apiContext);
    return (
        <>
            <Grid item xs={12} md={8} lg={9}>
                <Paper
                    className='bg-card'
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography component="h2" variant="h5" className='main-color'>
                        Tentang Saya
                    </Typography>
                    <Divider className='my-2 main-color' />

                    <Typography variant="subtitle1" paragraph color="text.secondary">
                        {
                            user.biodata && user.instansi &&
                            <p>
                                {user.nama}({user.biodata?.nidon}), Lahir di {user.biodata?.tempat_lahir} pada tanggal {user.biodata?.tanggal_lahir}. Saat ini bekerja pada <span className='text-capitalize'>{user.biodata?.instansi?.kode_skpd === '02' ? 'Fakultas Hukum' : user.biodata?.instansi?.kode_skpd === '03' ? 'Fakultas Ekonomi' : user.biodata?.instansi?.kode_skpd === '04' ? 'Fakultas Pertanian' : user.biodata?.instansi?.kode_skpd === '05' ? 'Fakultas KIP' : user.biodata?.instansi?.kode_skpd === '06' ? 'Fakultas Teknik' : '-'}</span> program studi <span className='text-capitalize'>{user.biodata?.instansi?.kode !== '01' || user.biodata?.instansi?.kode !== '03' || user.biodata?.instansi?.kode !== '07' || user.biodata?.instansi?.kode !== '11' || user.biodata?.instansi?.kode !== '22' ? user.biodata?.instansi.nama : '-'}</span> .
                            </p>
                        }

                        <h5>Riwayat pendidikan:</h5>
                        <ul>
                            <li>S1 - {user.pendidikan_s1 ? user.pendidikan_s1?.jurusan + ', ' + user.pendidikan_s1?.nama_sekolah + ', ' + user.pendidikan_s1?.lokasi + '. (' + user.pendidikan_s1?.tahun_lulus + ')' : ''}</li>
                            <li>S2 - {user.pendidikan_s2 ? user.pendidikan_s2?.jurusan + ', ' + user.pendidikan_s2?.nama_sekolah + ', ' + user.pendidikan_s2?.lokasi + '. (' + user.pendidikan_s2?.tahun_lulus + ')' : ''}</li>
                            <li>S3 - {user.pendidikan_s3 ? user.pendidikan_s3?.jurusan + ', ' + user.pendidikan_s3?.nama_sekolah + ', ' + user.pendidikan_s3?.lokasi + '. (' + user.pendidikan_s3?.tahun_lulus + ')' : ''}</li>
                        </ul>
                        <h5>Fokus riset penelitian:</h5>
                        <p>{user.profile?.fokus}</p>
                    </Typography>

                </Paper>

            </Grid>

            <Grid item xs={12} md={4} lg={3}>
                <Paper
                    className='bg-card'
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {user.profile?.foto ? <img src={API_URL + '/' + user.profile.foto} className="w-75 h-75 mx-auto" /> : <AccountBoxIcon className="w-75 h-75 mx-auto" />}
                    <div className='text-center mt-2'>
                        <strong>{user.biodata?.gelar_depan} {user.nama}, {user.biodata?.gelar_belakang}</strong>
                    </div>
                    {
                        user.nip && <div className='text-center'>
                            ({user.nip})
                        </div>
                    }
                </Paper>
            </Grid>
        </>
    )
}