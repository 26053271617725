import React, { useContext, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import CardTabel from './user/nav/CardTabel';
import { Box, Container, TableCell } from '@mui/material';
import { UseTableSearch } from './user/nav/UseTableSearch';
import scopus from '../assets/img/scopus.png'
import thomson from '../assets/img/thomsonReuters.png'
import google from '../assets/img/googleScholar.png'
import sinta from '../assets/img/garuda.png'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { Link, useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import HeaderLogin from './main/nav-login/HeaderLogin';
const theme = createTheme();
export default function Repository() {
    let count = 0;
    const [searchVal, setSearchVal] = React.useState(null);
    const navigate = useNavigate();
    const gotoGrafik = () => {
        navigate('/');
    }
    const [tabel, setTabel] = React.useState([]);
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const { data } = await axios.get(`/data-get`);
        if (data) {
            setData(data);
        }
    }
    const fetchRepo = async () => {
        const { data } = await axios.get('repo');
        if (data) {
            setTabel(data);
        }
        return { data };
    }
    useEffect(() => {
        fetchData();
        fetchRepo();
    }, [])
    const th = [
        {
            label: 'No',
            width: '2%'
        },
        {
            label: 'Author/Bidang Keahlian',
            width: '30%'
        },
        {
            label: 'Fakultas',
            width: '10%'
        },
        {
            label: 'ID Publikasi',
            width: '20%'
        }

    ]
    const item = (row, setEdit,num) => {
        return (<>
            <TableCell className="align-top pb-1">
                {num}.
            </TableCell>
            <TableCell className="align-top pb-1">
                <Link to={`/public/profil/${row.user.id}`} className='text-decoration-none' style={{ gap: '12px' }}>
                    <p style={{ fontSize: '18px' }} className="m-0">
                        <b>{row.user.nama}.,</b> <b>{row.gelar_depan},</b> <b>{row.gelar_belakang}</b>
                    </p>
                </Link>
                <p style={{ fontSize: '16px' }}><i>{row.user.profile.fokus}</i></p>
            </TableCell>
            <TableCell className="align-top pb-1">
                <p style={{ fontSize: '18px' }}>{row.fakultas}</p>
            </TableCell>
            <TableCell className="align-top pb-1 ">
                {
                    row.user.profile.scopus &&
                    <a href={row.user.profile.scopus} target="_blank" className='text-decoration-none'> <img src={scopus} alt="scopus" width="30" /></a>
                }
                {
                    row.user.profile.thomson &&
                    <a href={row.user.profile.thomson} target="_blank" className='text-decoration-none'>  <img src={thomson} alt="thomson" width="80" /></a>
                }
                {
                    row.user.profile.google &&
                    <a href={row.user.profile.google} target="_blank" className='text-decoration-none'> <img src={google} alt="google" width="50" /></a>
                }
                {
                    row.user.profile.sinta &&
                    <a href={row.user.profile.sinta} target="_blank" className='text-decoration-none'> <img src={sinta} alt="sinta" width="80" /></a>
                }

            </TableCell>
        </>)
    }
    
    const { loading } = UseTableSearch({
        searchVal,
        retrieve: fetchRepo,
        setTabel: setTabel
    });
    return (
         <ThemeProvider theme={theme}>
            <HeaderLogin data={data} grafik={gotoGrafik} />
            <Grid item xs={12} sm={8} md={3} className={`position-absolute login-container bg-transparent `} component={Paper} elevation={6} square style={{ boxShadow: 'none', marginLeft: '40px' }}>
                <Box
                    className='bookmark'
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: { xs: '129px', md: '203px' }, margin: '0px 0px',
                        height: { xs: '141px', md: '212px' },

                    }}
                >

                    {/* <Typography component="h1" variant="h5">
                            Login
                        </Typography> */}

                    {/* <Footer /> */}
                    <div className='w-100 mb-3 text-center mt-1 '>

                    </div>
                </Box>
               
              
            </Grid>
            <Container maxWidth="lg" sx={{ mt: 7, mb: 4 }}>
                <CardTabel
                    title={'Repository'}
                    modalTitle={'User'}
                    search={setSearchVal}
                    th={th}
                    custome={true}
                    tabel={tabel}
                    item={item}
                    input={false}
                    formID={'user-form'}
                    color={'light'}
                    opsi={false}
                />
            </Container>
        </ThemeProvider>
       
    );
}