import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Navbar from './nav/Navbar';
import Footer from '../main/nav/Footer';
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { apiContext } from '../../api';
import useAuth from '../main/Auth/useAuth';
import { Link, useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect';
import NavbarReview from './nav/NavbarReview';
import CardTabel from './nav/CardTabel';
import { UseTableSearch } from './nav/UseTableSearch';
const mdTheme = createTheme();
const columns = [
    { id: 'id', label: 'No', width: 2 },
    { id: 'nama', label: 'Nama Akun', minWidth: 13 },
    { id: 'nip', label: 'No NIK', minWidth: 13 },
    { id: 'option', label: 'Publikasi', minWidth: 5 },

];
export default function DashboardReview() {
    const navigate = useNavigate()
    const { axios, API_URL } = React.useContext(apiContext);
    const { user } = useAuth();
    const [open, setOpen] = React.useState(true);
  
    const [userData, setUserData] = React.useState([]);
    const fetchUser = async () => {
        const {data} = await axios.get(`/reviewer/data`);
        if (data) {
            setUserData(data);
        }
        return { data };
    }
    React.useEffect(() => {
        fetchUser();
    }, [])
    let count = 0;
    const [searchVal, setSearchVal] = React.useState(null);
    const [tabel, setTabel] = React.useState([]);
   

    const th = [
        {
            label: 'No',
            width: '2%'
        },
        {
            label: 'Nama Akun',
            width: '30%'
        },
        {
            label: 'NIP',
            width: '10%'
        },
        {
            label: 'Publikasi',
            width: '20%'
        }

    ]
    const item = (row, setEdit) => {
        count += 1;
        return (<>
            <TableCell className="align-top pb-1">
                {count}.
            </TableCell>
            <TableCell className="align-top pb-1">
               {row.nama}
            </TableCell>
            <TableCell className="align-top pb-1">
              {row.nip}
            </TableCell>
            <TableCell className="align-top pb-1 ">
                <Link to={`/reviewer/publikasi/${row.id}`} className='text-decoration-none'>
                    Lihat Publikasi
                </Link>
            </TableCell>
        </>)
    }

    const { loading } = UseTableSearch({
        searchVal,
        retrieve: fetchUser,
        setTabel: setUserData
    });
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <NavbarReview title={'Data Akun'} />

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Paper
                                    className='bg-card'
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography component="h2" variant="h5" className='main-color'>
                                        Data Akun
                                    </Typography>
                                    <Divider className='my-2 main-color' />
                                    <CardTabel
                                        title={''}
                                        modalTitle={'User'}
                                        search={setSearchVal}
                                        th={th}
                                        custome={true}
                                        tabel={userData}
                                        item={item}
                                        input={false}
                                        formID={'user-form'}
                                        color={'light'}
                                        opsi={false}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <Footer />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
