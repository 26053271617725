import React, { useContext, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import CardTabel from './user/nav/CardTabel';
import { TableCell } from '@mui/material';
import { UseTableSearch } from './user/nav/UseTableSearch';
import scopus from '../assets/img/scopus.png'
import thomson from '../assets/img/thomsonReuters.png'
import google from '../assets/img/googleScholar.png'
import sinta from '../assets/img/garuda.png'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

const theme = createTheme();
export default function Cari() {
    const [tabel, setTabel] = React.useState([]);
    const navigate = useNavigate();
    const { state } = useLocation();
    const { param } = state || {};
    const [searchVal, setSearchVal] = React.useState(param);
    const fetchData = async () => {
        const { data } = await axios.get(`cari/${param}`);
        if (data) {
            setTabel(data);
        }
        return { data };
    }
    useEffect(() => {
        fetchData();
    }, [param])
    const th = [
        {
            label: 'No',
            width: '2%'
        },
        {
            label: 'Title Publikasi',
            width: '30%'
        },
        {
            label: 'Penulis/Author',
            width: '20%'
        },
        {
            label: 'Jenis Publikasi',
            width: '10%'
        },
        {
            label: 'Email',
            width: '20%'
        }

    ]
    const item = (row, setEdit) => {
        let count = 1;
        return (<>
            <TableCell className="align-top pb-1">
            {count++}.
            </TableCell>
            <TableCell className="align-top pb-1">
                <a href={row.url} target="_blank">
                    <p style={{ fontSize: '18px' }}>{row.judul}</p>
                </a>
            </TableCell>
            <TableCell className="align-top pb-1">
                <p style={{ fontSize: '18px' }}>{row.nama}</p>
            </TableCell>
            <TableCell className="align-top pb-1">
                <p style={{ fontSize: '18px' }}>{row.tipe}</p>
            </TableCell>
            <TableCell className="align-top pb-1">
                <p style={{ fontSize: '18px' }}>{row.email}</p>
            </TableCell>
        </>)
    }
    useEffect(() => {
        const keyDownHandler = e => {
            if (e.key === 'Enter') {
                e.preventDefault();
                navigate('/cari', { state: {  param: e.target.value } })
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);
    return (
        <ThemeProvider theme={theme}>
            <Grid className='p-4' component="main">
                <Link to="/" className='text-decoration-none d-flex mt-4 fw-bold mb-4' style={{ gap: '12px' }}>
                    <ArrowBackIosNewRoundedIcon />
                    Kembali
                </Link>
                <CardTabel
                    title={'Hasil Pencarian berdasarkan Title'}
                    modalTitle={'User'}
                    search={setSearchVal}
                    searchVal={searchVal}
                    th={th}
                    custome={true}
                    tabel={tabel}
                    item={item}
                    input={false}
                    formID={'user-form'}
                    color={'light'}
                    opsi={false}
                />
               
            </Grid>
            
        </ThemeProvider>
    );
}