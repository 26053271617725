import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom'
import { apiContext } from '../../api';
function Logout(){
    const { axios } = useContext(apiContext);
    React.useEffect(async () => {
        await axios.post('/logout');
        localStorage.clear();
    }, [])
    window.open('/', "_self")
}
export default Logout;