import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CameraFrontIcon from '@mui/icons-material/CameraFront';
import LogoutIcon from '@mui/icons-material/Logout';
import ArticleIcon from '@mui/icons-material/Article';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { Link } from 'react-router-dom';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BookIcon from '@mui/icons-material/Book';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
export const MainListItems = ()=> {
    return(
        <div>
            <Link to={'/dashboard'} className='text-decoration-none'>
                <ListItemButton className='pt-3 main-color'>
                    <ListItemIcon className='main-color'>
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
            </Link>
            <Link to={'/profil'} className='text-decoration-none'>
                <ListItemButton className=' pt-3 main-color'>
                    <ListItemIcon className='main-color'>
                        <CameraFrontIcon />
                    </ListItemIcon>
                    <ListItemText primary="Profil" />
                </ListItemButton>
            </Link>
            <Link to={'/Publikasi'} className='text-decoration-none'>
                <ListItemButton className=' pt-3 main-color'>
                    <ListItemIcon className='main-color'>
                        <ArticleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Input Publikasi" />
                </ListItemButton>
            </Link>
            <Link to={'/data'} className='text-decoration-none'>
                <ListItemButton className=' pt-3 main-color'>
                    <ListItemIcon className='main-color'>
                        <PostAddOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Data Publikasi" />
                </ListItemButton>
            </Link>
        </div>
    )
    
}

export const secondaryListItems = (
    <div>
        <Link to={'/logout'} className='text-decoration-none text-danger'>
            <ListItemButton>
            <ListItemIcon className='text-danger'>
                <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
        </ListItemButton>
        </Link>
    </div>
);