import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Navbar from './nav/Navbar';
import Footer from '../main/nav/Footer';
import { Button, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { apiContext } from '../../api';
import useAuth from '../main/Auth/useAuth';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Swal from 'sweetalert2';

const mdTheme = createTheme();
const columns = [
    { id: 'id', label: 'No', width: 2 },
    { id: 'judul', label: 'Title Publikasi', minWidth: 30 },
    { id: 'tipe', label: 'Jenis Publikasi', minWidth: 13 },
    { id: 'tahun', label: 'Tahun', minWidth: 13 },
    { id: 'file', label: 'File Publikasi', minWidth: 13 },
    { id: 'review', label: 'Peer-Review', minWidth: 13 },
    { id: 'turnitin', label: 'Turnitin', minWidth: 13 },
    { id: 'koresponden', label: 'Koresponden', minWidth: 13 },
    { id: 'option', label: 'Option', minWidth: 5 },
];
export default function Data() {
    const { axios, API_URL } = React.useContext(apiContext);
    const { user } = useAuth();
    const [data, setData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);
    const openFile = (id) => {
        document.getElementById(id).click()
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const submit = async (e, tipe, column, id) => {
        if (e.target.files[0]) {
            const data = new FormData();
            data.set('table_id', id);
            data.set('tipe', tipe);
            data.set('column', column);
            data.append('file', e.target.files[0]);
            await axios.post('/fileUpload', data, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }).then(
                response => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Berhasil disimpan',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    fetchData();
                    e.target.value = null;
                }
            )
                .catch(error => {
                    e.target.value = null;
                    Swal.fire({
                        icon: 'error',
                        title: 'Gagal',
                        text: 'Mohon coba input kembali',
                    })
                });
        }

    }
    var count = 0;
    const fetchData = async () => {
        const response = await axios.get(`/data`);
        const data = response.data;
        if (data) {
            setData(data);
        }
    }
    React.useEffect(() => {
        fetchData();
    }, [])
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar title={'Data Publikasi'} />

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Paper
                                    className='bg-card'
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <TableContainer sx={{ maxHeight: 440 }}>
                                        <Table aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                        >
                                                            <strong>{column.label}</strong>
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row) => {
                                                        count += 1;
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                <TableCell>
                                                                    {count + (page * rowsPerPage)}
                                                                </TableCell>
                                                                {columns.slice(1, 4).map((column) => (
                                                                    <TableCell>
                                                                        {row[column.id]}
                                                                    </TableCell>
                                                                ))}
                                                                <TableCell>
                                                                    {row.file ? <a target="_blank" href={API_URL + '/storage/' + row.file}>
                                                                        <PictureAsPdfIcon className='mx-auto' color="error" />
                                                                    </a> : 'TIDAK ADA'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <input type="file" accept="application/pdf" className='d-none' id={row.id +'_'+ row.tipe +'_review'} onChange={(e) => submit(e, row.tipe, 'review', row.id)} />
                                                                    {
                                                                        row.file_review?.map((item, index) => {
                                                                            return (
                                                                                <a target="_blank" href={API_URL + '/storage/' + item.file}> <PictureAsPdfIcon className='mx-auto' color="error" />
                                                                                </a>
                                                                            )
                                                                        })
                                                                    }
                                                                    <Button onClick={(e) => openFile(row.id + '_' + row.tipe + '_review')} className='bg-transparent shadow-none w-0 p-0' variant='contained'><AddBoxIcon color="primary" /></Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <input type="file" accept="application/pdf" className='d-none' id={row.id + '_' + row.tipe + '_turnitin'} onChange={(e) => submit(e, row.tipe, 'turnitin', row.id)} />
                                                                    {
                                                                        row.file_turnitin?.map((item, index) => {
                                                                            return (
                                                                                <a target="_blank" href={API_URL + '/storage/' + item.file}> <PictureAsPdfIcon className='mx-auto' color="error" />
                                                                                </a>
                                                                            )
                                                                        })
                                                                    }
                                                                    <Button onClick={(e) => openFile(row.id + '_' + row.tipe + '_turnitin')} className='bg-transparent shadow-none w-0 p-0' variant='contained'><AddBoxIcon color="primary" /></Button>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <input type="file" accept="application/pdf" className='d-none' id={row.id + '_' + row.tipe + '_koresponden'} onChange={(e) => submit(e, row.tipe, 'koresponden', row.id)} />
                                                                    {
                                                                        row.file_koresponden?.map((item, index) => {
                                                                            return (
                                                                                <a target="_blank" href={API_URL + '/storage/' + item.file}> <PictureAsPdfIcon className='mx-auto' color="error" />
                                                                                </a>
                                                                            )
                                                                        })
                                                                    }
                                                                    <Button onClick={(e) => openFile(row.id + '_' + row.tipe + '_koresponden')} className='bg-transparent shadow-none w-0 p-0' variant='contained'><AddBoxIcon color="primary" /></Button>
                                                                </TableCell>
                                                                <TableCell>

                                                                </TableCell>
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[20, 50, 100]}
                                        component="div"
                                        count={data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                        <Footer />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
