import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { Container, InputAdornment, Toolbar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { isMobile } from 'react-device-detect';
import HeaderLogin from '../../main/nav-login/HeaderLogin';
import Footer from '../../main/nav/Footer';
import GrafikJurnal from '../GrafikJurnal';
import { apiContext } from '../../../api';
import Chart from "react-apexcharts";
import Biodata from '../component/Biodata';
import Jurnal from '../component/Jurnal';
const theme = createTheme();
export default function PublikasiP() {
    const { id, state } = useParams();
    const grafik = useRef(null);
    const gotoGrafik = () => grafik.current.scrollIntoView()
    const [data, setData] = useState([]);
    const [publikasi, setPublikasi] = useState([]);

    const fetchData = async () => {
        const { data } = await axios.get(`/data-get`);
        if (data) {
            setData(data);
        }
    }
    const fetchJurnal = async () => {
        const response = await axios.get(`/jurnal-get/${id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }
    }
    const fetchSeminar = async () => {
        const response = await axios.get(`/workshop-get/${id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }
    }
    const fetchBuku = async () => {
        const response = await axios.get(`/buku-get/${id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }

    }
    const fetchPaten = async () => {
        const response = await axios.get(`/paten-get/${id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }

    }
    const fetchProsiding = async () => {
        const response = await axios.get(`/prosiding-get/${id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }

    }
    const fetchLaporan = async () => {
        const response = await axios.get(`/nonpublish-get/${id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }
    }
    const fetchOpini = async () => {
        const response = await axios.get(`/opini-get/${id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }

    }
    const fetchKarya = async () => {
        const response = await axios.get(`/karya-get/${id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }

    }
    const fetchPoster = async () => {
        const response = await axios.get(`/poster-get/${id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }

    }
    useEffect(() => {
        fetchData();
        if (state === 'Jurnal') {
            fetchJurnal();
        } else if (state === 'Seminar') {
            fetchSeminar();
        } else if (state === 'Buku') {
            fetchBuku();
        } else if (state === 'Paten') {
            fetchPaten();
        } else if (state === 'Prosiding') {
            fetchProsiding();
        } else if (state === 'Laporan') {
            fetchLaporan();
        } else if (state === 'Opini') {
            fetchOpini();
        } else if (state === 'Karya') {
            fetchKarya();
        } else if (state === 'Poster') {
            fetchPoster();
        }
    }, [])

    return (
        <ThemeProvider theme={theme}>
            <HeaderLogin data={data} grafik={gotoGrafik} />
            <Jurnal data={publikasi} state={state}/> 
        </ThemeProvider>
    );
}