import React, { useEffect } from "react";
import PropTypes from "prop-types";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
// components

import { Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material";
export default function CardTabel({ color, title, modalTitle, itemModal, th, tabel, submit, update, destroy, reset, formID, input = true, item, custome = false, editInput = false, search, opsi = true, searchVal }) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    let count = 1;

    const [edit, setEdit] = React.useState(editInput);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        count = 1;
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <>
            {
                input && <div className="modal fade" id="add-modal" tabindex="-1" aria-labelledby="add-modalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title font-bold" id="exampleModalLabel">{edit ? 'Ubah' : 'Tambah'} {modalTitle}</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={(e) => { edit ? update(e, edit) : submit(e) }} id={formID}>
                                {itemModal(edit ? edit : false, setEdit)}
                                <div className="modal-footer">
                                    <Button type="button" variant="contained" color="error" className="me-3" data-bs-dismiss="modal">Batal</Button>
                                    <Button type="submit" variant="contained" data-bs-dismiss="modal">{edit ? 'Ubah' : 'Tambah'}</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }

            <div
                className={
                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
                    (color === "light" ? "bg-white" : "bg-blueGray-700 text-white")
                }
            >
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full max-w-full flex-grow flex-1">
                            <h4
                                className={
                                    "font-semibold text-lg float-left" +
                                    (color === "light" ? "text-blueGray-700" : "text-white")
                                }
                            >
                                {title}
                            </h4>
                            
                           
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto px-4 pb-4">
                    <div className="flex flex-row-reverse align-items-end">
                        {
                            input && submit && <Button
                                variant="contained" className="bg-primary ms-2"
                                data-bs-toggle="modal" data-bs-target="#add-modal" onClick={() => { reset(); setEdit(false) }}>
                                Tambah
                            </Button>
                        }
                        {
                            search &&
                            <TextField
                                onChange={e => search(e.target.value)}
                                className="mt-2 w-25"
                                label="Cari Nama"
                                value={searchVal}
                            />
                        }
                        
                    </div>
                  
                    {/* Projects table */}
                    <TableContainer sx={{ maxHeight: 440 }}>


                        <Table stickyHeader aria-label="sticky table">
                            
                            <TableHead>
                                <TableRow>
                                    {th.map((column, id) => (
                                        <TableCell
                                            key={id}
                                            align={column.align}
                                            style={{ width: column.width }}
                                            className="uppercase"
                                        >
                                            <strong>{column.label}</strong>
                                        </TableCell>
                                    ))}
                                    {
                                        opsi ?  <TableCell
                                            align={'center'}
                                            style={{ minWidth: '15%' }}
                                            className="uppercase"
                                        >
                                            <strong>opsi</strong>
                                        </TableCell> : <></>
                                    }
                                 
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tabel
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, id) => {
                                        let num = page * rowsPerPage + count;
                                        count += 1;
                                        return (
                                            <>
                                                <TableRow hover role="checkbox" tabIndex={-1} key={num}>
                                                    {
                                                        input && !custome ? <>
                                                            {th.map((column) => {
                                                                const value = row[column.col];
                                                                return (
                                                                    <TableCell key={column.id} className="align-top">
                                                                        {column.format && typeof value === 'number'
                                                                            ? column.format(value)
                                                                            : value}
                                                                    </TableCell>
                                                                );
                                                            })}
                                                            <TableCell align={'center'}>

                                                                <IconButton data-bs-toggle="modal" data-bs-target="#add-modal" onClick={() => { setEdit(row) }}><EditRoundedIcon color="secondary" /></IconButton>
                                                                {
                                                                    destroy ? <IconButton onClick={(e) => { destroy(e, row.id) }}><DeleteRoundedIcon color="error" /></IconButton> : <></>
                                                                }

                                                            </TableCell>
                                                        </> : custome ? item(row, setEdit,num) : item(row,num)
                                                    }
                                                </TableRow>
                                               
                                            </>
                                           
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={tabel.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </>
    );
}

CardTabel.defaultProps = {
    color: "light",
};

CardTabel.propTypes = {
    color: PropTypes.oneOf(["light", "dark"]),
};
