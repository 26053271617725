import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Navbar from './nav/Navbar';
import Footer from '../main/nav/Footer';
import { Button, Divider, Typography } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { apiContext } from '../../api';
import useAuth from '../main/Auth/useAuth';
import BarChartIcon from '@mui/icons-material/BarChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { Link } from 'react-router-dom';
import Chart from "react-apexcharts";
const mdTheme = createTheme();

export default function DataSaya() {
    const { axios, API_URL } = React.useContext(apiContext);
    const { user } = useAuth();
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const user_id = localStorage.getItem("user_id");
    const [publikasi, setPublikasi] = React.useState([]);
    const [state,setState] = React.useState(false);
  
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar title={'Statistik'} />

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Paper
                                    className='bg-card'
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography component="h2" variant="h5" className='main-color'>
                                        Statistik
                                    </Typography>
                                    <Divider className='my-2 main-color' />
                                    <div className='pt-4 justify-content-center' style={{ gap: '40px' }}>
                                  
                                        {
                                                <Chart
                                                    options={{
                                                        plotOptions: {
                                                            bar: {
                                                                horizontal: true,
                                                            }
                                                        },
                                                        dataLabels: {
                                                            enabled: true
                                                        },
                                                        xaxis: {
                                                            categories: ['Seminar', 'Buku', 'Paten', 'Jurnal', 'Prosiding', 'Laporan', 'Opini', 'Karya', 'Poster'],
                                                        },
                                                        grid: {
                                                            xaxis: {
                                                                lines: {
                                                                    show: true
                                                                }
                                                            }
                                                        },
                                                        yaxis: {
                                                            reversed: false,
                                                            axisTicks: {
                                                                show: true
                                                            }
                                                        },
                                                    
                                                     
                                                    }}
                                                    series ={ [{
                                                        data: [user.seminar_count, user.buku_count, user.paten_count, user.jurnal_count, user.prosiding_count, user.non_publish_count,user.opini_count,user.karya_count,user.poster_count]
                                                    }]}
                                                    // series={[
                                                    //     {
                                                    //         name: "Seminar",
                                                    //         data: Object.values(data.seminar)
                                                    //     },
                                                    //     {
                                                    //         name: "Buku",
                                                    //         data: Object.values(data.buku)
                                                    //     },
                                                    //     {
                                                    //         name: "Paten",
                                                    //         data: Object.values(data.paten)
                                                    //     },
                                                    //     {
                                                    //         name: "Jurnal",
                                                    //         data: Object.values(data.jurnal)
                                                    //     },
                                                    //     {
                                                    //         name: "Prosiding",
                                                    //         data: Object.values(data.prosiding)
                                                    //     },
                                                    //     {
                                                    //         name: "Laporan",
                                                    //         data: Object.values(data.laporan)
                                                    //     },
                                                    //     {
                                                    //         name: "Opini",
                                                    //         data: Object.values(data.opini)
                                                    //     },
                                                    //     {
                                                    //         name: "Karya",
                                                    //         data: Object.values(data.karya)
                                                    //     },
                                                    //     {
                                                    //         name: "Poster",
                                                    //         data: Object.values(data.poster)
                                                    //     },
                                                    // ]}
                                                    
                                                    type={'bar'}
                                                    height="400"
                                                />
                                        }
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Footer />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
