import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Navbar from './nav/Navbar';
import Footer from '../main/nav/Footer';
import { Button, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { apiContext } from '../../api';
import useAuth from '../main/Auth/useAuth';
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect';
const mdTheme = createTheme();

export default function Publikasi() {
    const navigate = useNavigate()
    const { axios, API_URL } = React.useContext(apiContext);
    const { user } = useAuth();
    const [jenis,setJenis] = React.useState();
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const user_id = localStorage.getItem("user_id");
    const [publikasi, setPublikasi] = React.useState([]);
    const direct = (jenis) => {
        if(jenis){
            navigate('/' + jenis)
        }
    }

    const fetchPublikasi = async () => {
        const response = await axios.get(`/publikasi-get/${user_id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }

    }
    React.useEffect(() => {
        fetchPublikasi();
    }, [])
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar title={'Publikasi'} />

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <Paper
                                    className='bg-card'
                                    sx={{
                                        p: 2,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Typography component="h2" variant="h5" className='main-color'>
                                        Publikasi
                                    </Typography>
                                    <Divider className='my-2 main-color' />
                                    <FormControl className={`mx-auto ${isMobile ? 'w-100' :'w-50'}`}>
                                        <InputLabel>Jenis Publikasi</InputLabel>
                                        <Select
                                            label="Jenis Publikasi"
                                            onChange={e => setJenis(e.target.value)}
                                        >
                                            <MenuItem value={'seminar'}>Seminar</MenuItem>
                                            <MenuItem value={'buku'}>Buku</MenuItem>
                                            <MenuItem value={'paten'}>Paten</MenuItem>
                                            <MenuItem value={'jurnal'}>Jurnal</MenuItem>
                                            <MenuItem value={'prosiding'}>Prosiding</MenuItem>
                                            <MenuItem value={'nonpublish'}>Laporan yang tidak dipublikasikan</MenuItem>
                                            <MenuItem value={'opini'}>Opini</MenuItem>
                                            <MenuItem value={'karya'}>Karya Cipta Teknologi/Seni</MenuItem>
                                            <MenuItem value={'poster'}>Poster</MenuItem>
                                        </Select>
                                        <Button variant="contained" className='header-color' onClick={()=>{direct(jenis)}}>Tambah Data</Button>
                                    </FormControl>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Footer />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}
