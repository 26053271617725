import { useState, useEffect } from "react";

export const UseTableSearch = ({ searchVal, retrieve, setTabel }) => {
    const [origData, setOrigData] = useState([]);
    const [searchIndex, setSearchIndex] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        const crawl = (user, allValues) => {
            if (!allValues) allValues = [];
            for (var key in user) {
                if (typeof user[key] === "object") crawl(user[key], allValues);
                else allValues.push(user[key] + " ");
            }
            return allValues;
        };

        const fetchData = async () => {
            const { data: users } = await retrieve();
            setOrigData(users);
            setTabel(users);
            const searchInd = users.map(user => {
                const allValues = crawl(user);
                return { allValues: allValues.toString() };
            });
            setSearchIndex(searchInd);
            if (users) setLoading(false);
        };
        const delayDebounceFn = setTimeout(() => {
            fetchData();
        }, 700)
        return () => clearTimeout(delayDebounceFn)
    }, [searchVal]);

    useEffect(() => {
        if (searchVal) {
            const reqData = searchIndex.map((user, index) => {
                if (user.allValues.toLowerCase().indexOf(searchVal.toLowerCase()) >= 0)
                    return origData[index];
                return null;
            });
            setTabel(
                reqData.filter(user => {
                    if (user) return true;
                    return false;
                })
            );
        } else setTabel(origData);
    }, [searchVal, origData, searchIndex]);

    return { loading };
};
