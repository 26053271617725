import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Swal from 'sweetalert2';
import '../../assets/css/app.css'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Navbar from './nav/Navbar';
import Footer from '../main/nav/Footer';
import { apiContext } from '../../api';

const mdTheme = createTheme();
const columns = [
    { id: 'id', label: 'No', width: 2 },
    { id: 'Award', label: 'Award', minWidth: 95 },
    { id: 'option', label: 'Option', minWidth: 5 },

];

function Award() {
    const { axios } = React.useContext(apiContext);
    const user_id = localStorage.getItem("user_id");
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const date = new Date();
    const [jurnal, setJurnal] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [id, setId] = React.useState(null);
    const [nama, setNama] = React.useState("");
    const [tahun, setTahun] = React.useState("");
    const [oleh, setOleh] = React.useState("");
    const [negara, setNegara] = React.useState("");
    const [edit, setEdit] = React.useState(false);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    var count = 0;

    const fetchData = async () => {
        const response = await axios.get(`/award-get/${user_id}`);
        const data = response.data;
        if (data) {
            setJurnal(data);
        }

    }
    const editData = async (id) => {
        const response = await axios.get(`/award-edit/${id}`);
        const data = response.data;
        if (data) {
            setEdit(true);
            setId(data.id);
            setNama(data.nama);
            setTahun(data.tahun);
            setOleh(data.oleh);
            setNegara(data.negara);
        }
    }
    const deleteData = (event, id) => {
        Swal.fire({
            title: 'Apakah anda yakin?',
            text: "Data tidak dapat dikembalikan jika dihapus",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Hapus'
        }).then((result) => {
            if (result.isConfirmed) {
                deleted(event, id);
            }
        })

    }
    React.useEffect(() => {
        fetchData();
    }, [])

    const resetInput = () => {
        setId(null);
        setNama("");
        setTahun("");
        setOleh("");
        setNegara("");

    }
    const cancelEdit = () => {
        resetInput();
        setEdit(false);
    }
    const deleted = async (event, id) => {
        event.preventDefault();
        await axios.delete(`/award-delete/${id}`)
            .then(
                response => {
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Berhasil Dihapus',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    fetchData();
                }

            )
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal',
                    text: 'Mohon coba kembali',
                })
            });

    }
    const submit = async event => {
        event.preventDefault();
        const data = {
            id: id,
            user_id: user_id,
            nama: nama,
            tahun: tahun,
            oleh: oleh,
            negara: negara,

        }
        if (edit) {
            await axios.put(`/award-update/${id}`, data)
                .then(
                    response => {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchData();
                        resetInput();
                        setEdit(false);
                    }

                )
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Gagal',
                        text: 'Mohon coba input kembali',
                    })
                });

        } else {
            await axios.post('/award-post', data)
                .then(
                    response => {
                        Swal.fire({
                            position: 'center',
                            icon: 'success',
                            title: 'Berhasil disimpan',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        fetchData();
                        resetInput();
                    }

                )
                .catch(error => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Gagal',
                        text: 'Mohon coba input kembali',
                    })
                });

        }

    }
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar title={'Award'} />

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Paper className='bg-card' sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
                            <form onSubmit={submit}>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <div className='form-group mb-2'>
                                            <TextField required variant="outlined" label="Nama Penghargaan" value={nama} className="w-100" onChange={e => setNama(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='form-group mb-2'>
                                            <TextField
                                                label="Tanggal"
                                                type="date"
                                                sx={{
                                                    width: '100%'
                                                }}
                                                value={tahun}
                                                onChange={e => setTahun(e.target.value)}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <div className='form-group mb-2'>
                                            <TextField required variant="outlined" label="Pemberian oleh" value={oleh} className="w-100" onChange={e => setOleh(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='form-group mb-2'>
                                            <TextField required variant="outlined" placeholder='Banda Aceh, Indonesia' label="Kota , Negara" value={negara} className="w-100" onChange={e => setNegara(e.target.value)} />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-5'>

                                    </div>
                                    <div className='col-md-3'>

                                    </div>
                                    <div className='col'>
                                        {
                                            edit && <Button type='button' variant="outlined" color="error" onClick={cancelEdit} className="float-start">Batal Edit</Button>
                                        }
                                        <Button type='submit' variant="contained" className='float-end'>{edit ? 'Update' : 'Tambah'}</Button>
                                    </div>
                                </div>
                            </form>
                        </Paper>
                    </Container>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Paper className='bg-card' sx={{ width: '100%', overflow: 'hidden', p: 2 }}>
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ width: column.width }}
                                                >
                                                    <strong>{column.label}</strong>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {jurnal
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                count += 1;
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                        <TableCell>
                                                            {count}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.tahun}, {row.nama}, {row.oleh}, {row.negara}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Button variant='text' onClick={() => { editData(row.id) }}>
                                                                <EditIcon />
                                                            </Button>
                                                            <Button variant='text' color='error' onClick={(event) => { deleteData(event, row.id) }}><DeleteIcon /></Button>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={jurnal.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                        <Footer />

                    </Container>
                </Box>
            </Box >
        </ThemeProvider >
    );
}

export default Award