import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import axios from 'axios';
import { apiContext } from '../../../api';
import CardTabel from '../nav/CardTabel';
import { UseTableSearch } from '../nav/UseTableSearch';
import "gridjs/dist/theme/mermaid.css";
import { Grid } from 'gridjs-react';
import { html } from 'gridjs';

function Jurnal({ data, state, fetchPublikasi, setPublikasi }) {
    const capitalizeWords = (str) => {
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    const { API_URL } = React.useContext(apiContext);
    const [open, setOpen] = React.useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 7, mb: 4 }}>
            <Grid
                data={
                    data?.map((item, index) =>
                        [html(`<span class="fw-bold">${index += 1}</span>`),
                        state === 'paten' ?
                            html(`<strong>${item.nama}</strong>, <span>${item.tanggal}, ${item.url ? `<a href=${item.url}>${item.nama_paten}</a>` : item.nama_paten} , ${item.lembaga ? item.lembaga +',' : ''} Vol. ${item.no_paten}.</span>`) :
                            html(`<strong>${item.nama}</strong>, <span>${item.tahun}, ${item.url ? `<a href=${item.url}>${item.judul}</a>` : item.judul} , ${item.jurnal}, Vol. ${item.volume}, ${item.halaman}.</span>`)
                            
                        ]
                    )
                }
                columns={[
                    {
                        name: 'No',
                        width: '6%'
                    }, {
                        name: capitalizeWords(state),
                        width: '94%',
                    }]}
                style={{
                    th: {
                        'background-color': '#001525',
                        'color': 'white',
                        'font-size': '14px'
                    },
                }}
                search={true}
                pagination={{
                    enabled: true,
                    limit: 10,
                }}
            />
        </Container>
    );
}

export default Jurnal