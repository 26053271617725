import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from './pages/user/Dashboard';
import Profil from './pages/user/Profil';
import Jurnal from './pages/user/Jurnal';
import Prosiding from './pages/user/Prosiding';
import Main from './pages/main/Main';
import Logout from './pages/user/Logout';
import PrivateRoute from './pages/user/PrivateRoute';
import Workshop from './pages/user/Seminar';
import Award from './pages/user/Award';
import Buku from './pages/user/Buku';
import Api from './api';
import Profiles from './pages/main/Auth/Profiles';
import Paten from './pages/user/Paten';
import NonPublish from './pages/user/NonPublish';
import Opini from './pages/user/Opini';
import Karya from './pages/user/Karya';
import Poster from './pages/user/Poster';
import Statistik from './pages/user/Statistik';
import Seminar from './pages/user/Seminar';
import Publikasi from './pages/user/Publikasi';
import Data from './pages/user/Data';
import GrafikJurnal from './pages/user/GrafikJurnal';
import Login from './pages/Login';
import DataSaya from './pages/user/DataSaya';
import Home from './pages/Home';
import GrafikJurnalAuth from './pages/user/GrafikJurnalAuth';
import Repository from './pages/Repository';
import Cari from './pages/Cari';
import DashboardReview from './pages/user/DashboardReview';
import DataReview from './pages/user/DataReview';
import ProfilP from './pages/user/public/Profil';
import PublikasiP from './pages/user/public/PublikasiP';
import PublikasiAll from './pages/user/public/PublikasiAll';

function App() {
  return (
    <>
      <Api>
        <Router>
          <Profiles>
            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/profil' element={<Profil />} />
                <Route path='/jurnal' element={<Jurnal />} />
                <Route path='/prosiding' element={<Prosiding />} />
                <Route path='/seminar' element={<Seminar />} />
                <Route path='/award' element={<Award />} />
                <Route path='/buku' element={<Buku />} />
                <Route path='/paten' element={<Paten />} />
                <Route path='/nonPublish' element={<NonPublish />} />
                <Route path='/opini' element={<Opini />} />
                <Route path='/karya' element={<Karya />} />
                <Route path='/poster' element={<Poster />} />
                <Route path='/statistik' element={<Statistik />} />
                <Route path='/publikasi' element={<Publikasi />} />
                <Route path='/data' element={<Data />} />
                <Route path='/grafik-jurnal' element={<GrafikJurnal />} />
                <Route path='/data-saya' element={<DataSaya />} />
                <Route path='/data-jurnal' element={<GrafikJurnalAuth />} />
              </Route>
              <Route element={<PrivateRoute />}>
                <Route path='/reviewer/dashboard' element={<DashboardReview />} />
                <Route path='/reviewer/publikasi/:id' element={<DataReview />} />
              </Route>
            </Routes>
          </Profiles>
          <Routes>
            <Route exact path='/public/profil/:id' element={<ProfilP />} />
            <Route exact path='/public/profil/:id/:state' element={<PublikasiP />} />
            <Route exact path='/public/publikasi/:state' element={<PublikasiAll />} />
            <Route path='/cari' element={<Cari />} />
            <Route path='/repository' element={<Repository />} />
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            <Route exact path='/' element={<Home />} />
          </Routes>
        </Router>
      </Api>
    </>

  );
}

export default App;
