import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './nav/Header';
import Footer from './nav/Footer';
import { Button, Card, CardActionArea, CardContent, CardMedia, Divider, Link, Typography } from '@mui/material';
import bg from '../../assets/img/biro_unsam.jpg';
import logo from '../../assets/img/unsam.png';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useParams } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import '../../assets/css/app.css'
import { apiContext } from '../../api';
const theme = createTheme();

export default function Main() {
    const { axios, API_URL } = React.useContext(apiContext);
    const { id } = useParams();
    const [user, setUser] = React.useState([]);
    const [profile, setProfile] = React.useState([]);
    const [publikasi, setPublikasi] = React.useState([]);
    const [prosiding, setProsiding] = React.useState([]);
    const [jurnal, setJurnal] = React.useState([]);
    const [buku, setBuku] = React.useState([]);
    const [award, setAward] = React.useState([]);
    const [workshop, setWorkshop] = React.useState([]);
    const [foto, setFoto] = React.useState("");
    const jurnalRef = React.useRef(null)
    const bukuRef = React.useRef(null)
    const prosidingRef = React.useRef(null)
    const awardRef = React.useRef(null)
    const workshopRef = React.useRef(null)

    const fetchData = async () => {
        await axios.get(`/user-get/${id}`).then(
            response => {
                const data = response.data;
                document.title = data.user.name + " FSD Universitas Samudra";
                setUser(data.user);
                setProfile(data.profile);
                setPublikasi(data.publikasi);
                setProsiding(data.prosiding);
                setJurnal(data.jurnal);
                setBuku(data.buku);
                setAward(data.award);
                setWorkshop(data.workshop);
                if (data.user.foto) {
                    setFoto(API_URL + data.user.foto);
                }
            }

        )

    }
    React.useEffect(() => {
        fetchData();
        
        console.log(user)
    }, [])
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
    
            <Header title="Blog" className="w-100" jurnalRef={jurnalRef}
                bukuRef={bukuRef}
                prosidingRef={prosidingRef}
                awardRef={awardRef}
                workshopRef={workshopRef}/>

            <Container maxWidth="lg" >
                <main>
                    <Paper
                        sx={{
                            backgroundColor: 'grey.800',
                            color: '#fff',
                            mb: 4,
                            mt: {xs:40,sm:10},

                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${bg})`,
                            height: 400
                        }}
                    >
                        {<img style={{ display: 'none' }} src={bg} />}
                        <Box
                            sx={{
                                top: 0,
                                bottom: 0,
                                right: 0,
                                left: 0,
                            }}
                        />
                        <Box
                            sx={{
                                p: { xs: 3, md: 6 },
                            }}
                        >
                            <h2 className='text-center w-100 strokeme'>
                                {profile.gelarD} {user.name}{profile.gelarB ? ' , '+profile.gelarB:''}
                            </h2>

                        </Box>
                    </Paper>
                    <Card sx={{ display: 'flex', padding: 2, margin: 4, marginTop: '-19rem',py:5 }}>

                        <div className='row'>
                            <div className='col-md-3 text-center'>
                                <img src={foto ? foto : logo} style={{ width: 160, height: 160 }} />
                                <Typography variant="subtitle1" paragraph>
                                    {user.email}
                                </Typography>
                            </div>
                            <div className='col'>
                                <CardContent sx={{ flex: 1 }}>
                                    <Typography component="h2" variant="h5" color="text.secondary">
                                        Tentang Saya
                                    </Typography>
                                    <Typography variant="subtitle1" paragraph color="text.secondary">
                                        {profile.about}
                                    </Typography>
                                    <div>
                                        {publikasi.map((item) => {
                                            return (
                                                <a href={item.link}>
                                                    <img src={API_URL + item.img} width="160px" className='me-4' />
                                                </a>
                                            )
                                        })}
                                    </div>

                                </CardContent>

                            </div>
                            <div>
                                <Typography component="h2" variant="h5" color="text.secondary" className='mb-1'>
                                    Publikasi
                                </Typography>
                                {Object.keys(jurnal).length ? <>
                                    <Typography ref={jurnalRef} component="h2" variant="h6" color="text.secondary" className='mb-3'>
                                        jurnal
                                    </Typography >
                                    <div className='ms-2'>
                                        <Typography component="p" color="text.secondary">
                                            {jurnal.map((item) => {
                                                return (
                                                    <p key={item.id}><strong>{item.nama}</strong>, <span>{item.tahun}, {item.link ? <a href={item.link}>{item.judul}</a> : item.judul}, {item.jurnal}, Vol. {item.volume}, {item.halaman}</span></p>
                                                )
                                            })}
                                        </Typography >
                                    </div>
                                    <Divider className='my-3' />
                                    </> : ""
                                }
                                {Object.keys(prosiding).length ? <>
                                    <Typography ref={prosidingRef} component="h2" variant="h6" color="text.secondary" className='mb-3'>
                                        Prosiding
                                    </Typography >
                                    <div className='ms-2'>
                                        <Typography component="p" color="text.secondary">
                                            {prosiding.map((item) => {
                                                return (
                                                    <p key={item.id}><strong>{item.nama}</strong>, {item.tahun}, {item.link ? <a href={item.link}>{item.judul}</a> : item.judul}, {item.lainya}</p>
                                                )
                                            })}
                                        </Typography >
                                    </div>
                                    <Divider className='my-3' />
                                </> : ""
                                }
                                {Object.keys(workshop).length ? <>
                                    <Typography ref={workshopRef} component="h2" variant="h6" color="text.secondary" className='mb-3'>
                                        Workshop
                                    </Typography >
                                    <div className='ms-2'>
                                        <Typography component="p" color="text.secondary">
                                            {workshop.map((item) => {
                                                return (
                                                    <p key={item.id}>{item.tahun}, <i>{item.nama}</i>, {item.penyelenggara}, {item.lokasi}</p>
                                                )
                                            })}
                                        </Typography >
                                    </div>
                                    <Divider className='my-3' />
                                </> : ""
                                }
                                {Object.keys(buku).length ? <>
                                    <Typography ref={bukuRef} component="h2" variant="h6" color="text.secondary" className='mb-3'>
                                        Buku
                                    </Typography >
                                    <div className='ms-2'>
                                        <Typography component="p" color="text.secondary">
                                            {buku.map((item) => {
                                                return (
                                                    <p key={item.id}><strong>{item.nama}</strong>, {item.tahun}, <i>{item.link ? <a href={item.link}>{item.judul}</a> : item.judul}</i>, {item.jurnal}, Vol. {item.volume}, {item.halaman}</p>
                                                )
                                            })}
                                        </Typography >
                                    </div>
                                    <Divider className='my-3' />
                                </> : ""
                                }
                                {Object.keys(award).length ? <>
                                    <Typography ref={awardRef} component="h2" variant="h6" color="text.secondary" className='mb-3'>
                                        Penghargaan
                                    </Typography >
                                    <div className='ms-2'>
                                        <Typography component="p" color="text.secondary">
                                            {award.map((item) => {
                                                return (
                                                    <p key={item.id}>{item.tahun}, {item.nama}, {item.oleh}, {item.negara}</p>
                                                )
                                            })}
                                        </Typography >
                                    </div>
                                    <Divider className='my-3' />
                                </> : ""
                                }
                            </div>
                        </div>


                    </Card>
                </main>
            </Container>
            <Footer
            />
        </ThemeProvider>
    );
}