import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import Footer from './main/nav/Footer';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { apiContext } from '../api';
import HeaderLogin from './main/nav-login/HeaderLogin';
import { InputAdornment, Toolbar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import GrafikJurnal from './user/GrafikJurnal';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
const theme = createTheme();
export default function Home() {
    const grafik = useRef(null);
    const gotoGrafik = () => grafik.current.scrollIntoView()
    const { API_URL } = useContext(apiContext);
    const navigate = useNavigate();
    const [validation, setValidation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [dataAll,setDataAll] = useState([]);
    const fetchDataAll = async () => {
        const {data} = await axios.get('/dataall-get');
        setDataAll(data);
    }
    const fetchData = async () => {
        const { data } = await axios.get('/data-get');
        if (data) {
            setData(data);
        }
    }
    useEffect(() => {
        fetchData();
        fetchDataAll();
    }, [])
    useEffect(() => {
        const keyDownHandler = e => {

            if (e.key === 'Enter') {
                e.preventDefault();
                navigate('/cari', { state: { param: e.target.value } })
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <HeaderLogin data={data} grafik={gotoGrafik} />
            <Grid container component="main" sx={{ height: '100vh' }}>

                <Grid item xs={12} sm={8} md={3} className={`position-absolute login-container bg-transparent `} component={Paper} elevation={6} square style={{ boxShadow: 'none', marginLeft: '40px' }}>
                    <Box
                        className='bookmark'
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: { xs: '129px', md: '203px' }, margin: '0px 0px',
                            height: { xs: '141px', md: '212px' },

                        }}
                    >

                        {/* <Typography component="h1" variant="h5">
                            Login
                        </Typography> */}

                        {/* <Footer /> */}
                        <div className='w-100 mb-3 text-center mt-1 '>

                        </div>
                    </Box>
                </Grid>
                <Grid item className='w-100' onClick={() => { setOpen(false) }}>
                    <CssBaseline />
                    <div className=" w-100 d-flex">
                        <div className='col-md-6 bg-main'>

                        </div>
                        <div className='col-md-6 d-flex align-items-center header-color text-white px-4'>
                            <Box className={`me-4 ${isMobile ? '' : 'header-container'}`}>
                                {/* <h3 className='fw-bold ' style={{ textAlign: 'justify' }}>Repository Publikasi Universitas (RPU) adalah suatu sistem yang didesign untuk menampung data publikasi yang dihasilkan oleh dosen Universitas Samudra (Unsam)</h3> */}
                                <h1 className='fw-bold header-title'>Cari Data</h1>
                                <h4 className='header-body'>Cari data publikasi database dengan memasukkan keywords pada kolom berikut!</h4>
                                <TextField
                                    fullWidth
                                    id="Search"
                                    label="Tulis Keywords di sini ... "
                                    className='mt-4 search'
                                    InputLabelProps={{
                                        style: { color: 'white' },
                                    }}
                                    sx={{
                                        fieldset: { borderColor: "white" },
                                        input: { color: 'white', textSize: '18px' }
                                    }}

                                />
                            </Box>

                        </div>
                    </div>
                    <div className='row mx-auto'>
                        <div className={`col-md-4 ${isMobile ? '' : 'px-5'}`}>
                            <Toolbar />
                            <div className='mt-5'>
                                <h2 className='fw-bold main-color header-title fs-1'>Data Publikasi</h2>
                                <span>Jumlah Publikasi yang terdaftar pada RPU Unsam</span>
                                <div className='d-flex flex-column mt-4'>
                                    <span className='h5'>Seminar<Link to={'/public/publikasi/seminar'} className='text-decoration-none'> {dataAll.seminar}</Link> Data</span>
                                    <span className='h5 mt-2'>Buku<Link to={'/public/publikasi/buku'} className='text-decoration-none'> {dataAll.buku}</Link> Data</span>
                                    <span className='h5 mt-2'>Paten<Link to={'/public/publikasi/paten'} className='text-decoration-none'> {dataAll.paten}</Link> Data</span>
                                    <span className='h5 mt-2'>Jurnal<Link to={'/public/publikasi/jurnal'} className='text-decoration-none'> {dataAll.jurnal}</Link> Data</span>
                                    <span className='h5 mt-2'>Prosiding<Link to={'/public/publikasi/prosiding'} className='text-decoration-none'> {dataAll.prosiding}</Link> Data</span>
                                    <span className='h5 mt-2'>Laporan yang tidak dipublikasikan<Link to={'/public/publikasi/nonpublish'} className='text-decoration-none'> {dataAll.nonpublish}</Link> Data</span>
                                    <span className='h5 mt-2'>Opini<Link to={'/public/publikasi/opini'} className='text-decoration-none'> {dataAll.opini}</Link> Data</span>
                                    <span className='h5 mt-2'>Karya Cipta Teknologi/Seni<Link to={'/public/publikasi/karya'} className='text-decoration-none'> {dataAll.karya}</Link> Data</span>
                                    <span className='h5 mt-2'>Poster<Link to={'/public/publikasi/poster'} className='text-decoration-none'> {dataAll.poster}</Link> Data</span>
                                </div>
                            </div>
                            {/* <Button variant='contained' className='mt-4 ' style={{ backgroundColor:'rgb(113,215,180)'}}>Lihat Profile Author & Publikasi</Button> */}
                            {/* <Button variant='contained' className='mt-4 header-color text-white'>Lihat Profile Author & Publikasi</Button> */}
                        </div>
                        <div className={`col-md-8 ${isMobile ? 'p-0' : ''}`} ref={grafik}>
                            <GrafikJurnal auth={false} />
                            
                        </div>
                    </div>
                    <div className='header-color text-white'>
                        <Footer />
                    </div>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}