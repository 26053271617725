import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Navbar from './nav/Navbar';
import Footer from '../main/nav/Footer';
import { Button, Divider, Typography } from '@mui/material';
import { apiContext } from '../../api';
import useAuth from '../main/Auth/useAuth';
import { Link } from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/BarChart';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import Biodata from './component/Biodata';
const mdTheme = createTheme();

function DashboardContent() {
    const { axios, API_URL } = React.useContext(apiContext);
    const { user, profile } = useAuth();
    const [open, setOpen] = React.useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const user_id = localStorage.getItem("user_id");
    const [publikasi, setPublikasi] = React.useState([]);
    const fetchPublikasi = async () => {
        const response = await axios.get(`/publikasi-get/${user_id}`);
        const data = response.data;
        if (data) {
            setPublikasi(data);
        }
    }
    React.useEffect(() => {
        profile();
        fetchPublikasi();
    }, [])
    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <Navbar title={'Dashboard'} />

                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Biodata user={user}/>
                            <Paper
                                className='shadow-none mt-3 ms-4'
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <div className='d-flex pt-2 justify-content-center' style={{ gap: '10px' }}>
                                    <Link to={'/data-saya'} className='text-decoration-none'>
                                        <Button variant="contained" className='d-block primary-bg'>
                                            <BarChartIcon sx={{ fontSize: { sm: 40, md: 140 } }} />
                                            <p>Publikasi Saya</p>
                                        </Button>
                                    </Link>
                                    <Link to={'/data-jurnal'} className='text-decoration-none'>
                                        <Button variant="contained" className='d-block primary-bg'>
                                            <SsidChartIcon sx={{ fontSize: { sm: 40, md: 140 } }} />
                                            <p>Publikasi Publik</p>
                                        </Button>
                                    </Link>
                                </div>
                            </Paper>
                        </Grid>
                        <Footer />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default function Dashboard() {
    return <DashboardContent />;
}